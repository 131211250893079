<template>
    <div class="levels apartments page">
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12">
                    <router-link class="go-back animateScroll" :to="{name: 'apartments'}" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')">
                        <img src="@/assets/images/icons/ic-arrow-right-black-big.svg" />
                        {{tr('choose_floor_back')}}
                    </router-link>
                    <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')" v-html="tr('choose_building_title')"></h2>        
                    <p class="page-lead animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')">{{tr('choose_floor_lead')}}</p>      
                                          
                    <b-tabs 
                        no-fade
                        v-model="tabIndex" 
                        content-class="apartments__tab-content"                         
                        nav-wrapper-class="apartments__tab-head"
                    >                 
                        <b-tab>
                            <template v-slot:title>                                
                                <div @mouseover="showCurrentTab(-1)">
                                    {{tr('choose_floor_all')}}
                                </div>
                            </template>                            
                            <img v-if="currentSlug === 'villa'" :src="baseUrl + 'pictures/choose_floor/villa/00-all.jpg'" class="w-100 d-none d-md-block animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')"/>                            
                            <img v-else-if="currentSlug === 'apartments'" :src="baseUrl + 'pictures/choose_floor/apartments/00-apartment-all.jpg'" class="w-100 d-none d-md-block animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')"/>                            
                        </b-tab>            
                        <b-tab v-for="(level, apIndex) in levels" :key="apIndex">
                            <template v-slot:title>
                                <div @mouseover="showCurrentTab(apIndex)">
                                    <router-link :to="{ name: 'flats', params:{ level: level.slug, building_name: currentSlug }}" class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', `delay-${apIndex+1}`)">
                                        <span>{{ level.name[selectedLanguage] }}</span>                                        
                                    </router-link>                                    
                                </div>
                            </template>                            
                            <img :src="baseUrl + level.levelImgSrc" :alt="level.name" class="w-100 d-none d-md-block animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')"/>
                        </b-tab>                                                                                      
                    </b-tabs>        
                </b-col>                                    
            </b-row>        
        </b-container>        
        <div class="bgr-img animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')">
            <img src="@/assets/images/bgr/choose_building-mobile.jpg" />
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            levels:[],
            tabIndex: -1
        }
    },
    computed:{
        apartments(){
            return this.$store.state.apartments
        },
        currentSlug (){
            return this.$route.params.building_name   
        }
    },
    methods:{
        getLevels(){
            let currentSlug = this.$route.params.building_name            

            this.levels = this.apartments.find( item => item.slug === currentSlug).levels
        },
        showCurrentTab(index){            
            this.tabIndex = index + 1
        }
    },
    created(){
        this.getLevels()
    }
}
</script>